<template>
  <Banner />
  <ResourceAdministration/>
  <Footer />
  <CopyRight/>
</template>

<script>
import Banner from '@/components/banner.vue'
import ResourceAdministration from '@/components/service/resource-administration.vue'
import Footer from '@/components/footer.vue'
import CopyRight from '@/components/copy-right.vue'

export default {
  name:'Service',
  components: {
    Banner,
    ResourceAdministration,
    CopyRight,
    Footer
  }
}
</script>