<template>
  <HostLayoutContainer :style="{ height: '1220px' }">
    <HostLayoutItem>
      <div class="resoure_administration-container">
        <TitleWithUnderline :title="title" id="resoure-administration"/>
        <div class="resoure_administration-img">
          <img src="/assets/service/resource-administration@3x.png" alt="" />
        </div>
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithUnderline from '@/components/title/title-with-underline.vue'
export default {
  components: {
    TitleWithUnderline,
  },
  setup () {
    return {
      title: { marginBottom: '103px', marginTop: '75px', text: '融合云资源管理' },
    }
  }
}
</script>
<style lang="scss" scoped>
.resoure_administration-img {
  width: 1002px;
  height: 892px;
  margin: 0 99px;
}
</style>