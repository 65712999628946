export const data = [
  {
    type: 'group',
    text: '首页',
    children: [
      {text: '云产品', href: '/ubang/home#cloud-products-home'},
      {text: '公司介绍', href: '/ubang/home#introduce'},
      {text: '我们的客户', href: '/ubang/home#client'},
      {text: '安全、可靠、合规的云服务', href: '/ubang/home#certification'},
    ]
  },
  {
    type: 'group',
    text: '主要产品',
    children: [
      {text: '云产品', href: '/ubang/product#cloud-products'},
      {text: '云管理平台', href: '/ubang/product#cloud-platform'},
      {text: '功能总览', href: '/ubang/product#overview'},
      {text: '其他产品', href: '/ubang/product#other-products'},
    ]
  },
  {
    type: 'group',
    text: '云服务',
    children: [
      {text: '融合云资源管理', href: '/ubang/service#resoure-administration'},

    ]
  },
  {
    type: 'group',
    text: '解决方案',
    children: [
      {text: '混合云构建解决方案', href: '/ubang/solution#solution-mixture'},
      {text: '全新现代化办公解决方案', href: '/ubang/solution#solution-modernization'},
      {text: '云管理平台解决方案', href: '/ubang/solution#solution-cloud-platform'},
      {text: '客户成功案例', href: '/ubang/solution#success-case'},
    ]
  },
  {
    type: 'group',
    text: '关于我们',
    children: [
      {text: '加入我们', href: '/ubang/about#join-us'},
      {text: '关于我们', href: '/ubang/about#about'},
      {text: '联系我们', href: '/ubang/about#contact'},
    ]
  },
]
