<template>
  <div class="layout__title" :style="{marginBottom: title.marginBottom, marginTop: title.marginTop }">{{ title.text }}
  </div>
</template>
<script>
export default {
  props: {
    title: {type: Object, default: () => ({})},
  },
  setup (props) {
    const {marginBottom, marginTop, text} = props.title
    return {
      title: {marginBottom, marginTop, text},
    }
  },
}
</script>
<style lang="scss" scoped>
.layout__title {
  position: relative;
  text-align: center;

  height: 42px;
  line-height: 42px;
  font-size: 30px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #000C42;
  letter-spacing: 1px;
}

.layout__underline {
  width: 61px;
  height: 4px;
  background: linear-gradient(269deg, #15DEF7 0%, #0067FA 100%);
  border-radius: 2px;
}

.layout__title::after {
  position: absolute;
  content: '';
  display: block;
  left: 50%;
  transform: translateX(-50%);
  bottom: -13px;;
  @extend .layout__underline;
}
</style>
